// Import legacy polyfills
import 'mdn-polyfills/Element.prototype.matches';
import 'mdn-polyfills/Element.prototype.closest';
import 'mdn-polyfills/Node.prototype.remove';
import 'mdn-polyfills/NodeList.prototype.forEach';
import 'whatwg-fetch';
import 'url-polyfill';
import svg4everybody from 'svg4everybody';

// Import the main entry file after the polyfills
import './main';

svg4everybody();
